@import '~normalize.css/normalize.css';
@import '~bootstrap/scss/bootstrap';

@import './vars';
@import './typo';
@import './helpers';

@import './slick.min';
@import './accordion';
@import './slick-slider';
@import './btn';
@import './section';

*, *::after, *::before {
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

.content-container {
  max-width: 1280px;
  min-width: 360px;
  margin: 0 auto;
}