a {
  color: #000;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
    // text-decoration: underline;
  }
}

h1, .h1
h2, .h2 {
  font-weight: 700;
}

h1, .h1 {
  font-size: 64px;;
  line-height: 72px;
  margin-bottom: 24px;

  @media (max-width: $grid-lg) {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 0;
  }
}

h2, .h2 {
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 40px;

  @media (max-width: $grid-lg) {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
  }
}

img {
  max-width: 100%;
}

p {
  margin: 0;

  & + p {
    margin-top: 40px;

    @media (max-width: $grid-lg) {
      margin-top: 30px;
    }
  }
}