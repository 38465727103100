@import '../../styles/vars';

.ribbon {
    &:not(.is-horizontal) {
        position: absolute;
        transform: translateY(-50px);
        right: -8px;

        @media (max-width: $grid-md) {
            transform: translateY(-35px);
        }
    }

    &.is-horizontal {
        margin-left: 16px;

        @media (max-width: $grid-sm) {
            transform: translateY(-35px);
            position: absolute;
        }
    }

    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    width: max-content;
    color: #FFFFFF;

    padding: 8px 18px 8px 8px;
    background: #FF7131;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    clip-path: polygon(100% 0%, 92% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
}