@import '../../styles/vars';

.section-about-book {
  font-size: 24px;
  line-height: 34px;

  @media (max-width: $grid-lg) {
    font-size: 16px;
    line-height: 24px;
  }

  &__book {
    background: url(../../images/bubble.svg) 80% 40% no-repeat;
    background-size: 70%;
    position: relative;

    &:after {
      background: url(../../images/book-star.svg) no-repeat;
      background-size: contain;
      content: '';
      display: block;
      position: absolute;
      left: 15%;
      top: -5%;
      width: 139px;
      height: 139px;

      @media (max-width: $grid-lg) {
        width: 75px;
        height: 75px;
      }
    }
  }
}