@import '../../styles/vars';

.section-what-the-internet-says {
    text-align: center;
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;

        @media (max-width: $grid-md) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
        }
    }

    &__title {
        margin: 0;

        @media (max-width: $grid-md) {
            margin-bottom: 40px;
        }
    }

    &__ratings {
        display: flex;
        flex-direction: row-reverse;
        gap: 40px;
        position: relative;

        @media (max-width: $grid-md) {
            flex-direction: column;
            gap: 0;
        }

        .ratings__rating {
            display: flex;
            flex-direction: column;
            gap: 0;
            justify-content: center;
            align-items: flex-start;

            @media (max-width: $grid-md) {
                flex-direction: row;
                justify-content: flex-start;
            }

            .ratings__reviews {
                font-size: 16px;
                line-height: 20px;
                text-decoration: underline;
                position: relative;
                top: -3px;

                @media (max-width: $grid-md) {
                    display: none;
                }
            }

            .stars-rating__reviews-amount {
                @media (min-width: $grid-md) {
                    display: none;
                }
            }
        }

        .stars-rating__logo {
            width: 100%;
        }

        .ratings__rating:nth-child(2) {
            .stars-rating__logo {
                top: 0;
            }
        }
    }

    &__cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;

        @media (max-width: $grid-md) {
            row-gap: 16px;
        }

        .cards__card {
            flex-basis: calc(33% - 12px);
            cursor: pointer;

            @media (max-width: $grid-md) {
                flex-basis: 100%;
            }

            text-align: center;
            line-height: 21px;

            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 48px 36px;
            border-radius: 10px;

            &.highlighted {
                background-color: #FFF4EF;
            }

            .card__subtext {
                padding-top: 11px;
            }

            .card__author {
                color: #717171;
            }

            .card__pretext {
                font-weight: 500;
            }

            .card__source {
                color: #FF7A3F;
                font-style: italic;
            }
        }
    }
}