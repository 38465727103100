@import '../../styles/vars';

.subscribe-form {
	max-width: 525px;

	&__title {
		font-weight: 700;
		font-size: 32px;
		line-height: 42px;
		margin-bottom: 24px;

		@media (max-width: $grid-md) {
			font-size: 24px;
			line-height: 32px;
		}
	}
}

.input-text {
	border: .5px solid rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	border-radius: 5px;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	padding: 12px 11px;
	width: 100%;
}
