@import '../../styles/vars';

.section-book-presentation {
  display: grid;
  grid-template-areas:
    'book author'
    'book title'
    'book rating'
    'book description'
    'book amazon'
    'book share'
    'book footer'
    'book available-links';
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;

@media (max-width: $grid-md) {
  grid-template-areas:
  'author'
  'title'
  'book'
  'rating'
  'description'
  'amazon'
  'share'
  'footer'
  'available-links';
  grid-template-columns: 1fr;
}

  &__book {
    grid-area: book;

    @media (max-width: $grid-md) {
      margin-bottom: 30px;
    }
  }

  &__author {
    grid-area: author;
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 8px;

    @media (max-width: $grid-md) {
      font-size: 24px;
    }
  }

  &__title {
    grid-area: title;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;

    @media (max-width: $grid-md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__rating {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media (max-width: $grid-md) {
      margin-top: 24px;
    }
  }

  &__description {
    grid-area: description;
    margin-bottom: 30px;

    @media (max-width: $grid-md) {
      margin-bottom: 24px;
    }
  }

  &__amazon {
    grid-area: amazon;
    margin-left: -16px;
    margin-bottom: 48px;

    @media (max-width: $grid-md) {
      margin-bottom: 32px;
    }
  }

  &__share {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;

    grid-area: share;
  }

  &__share-text {
    margin-right: 60px;

    @media (max-width: $grid-md) {
      margin-bottom: 24px;
      margin-right: 24px;
    }
  }

  &__footer {
    &_bold {
      font-weight: bold;
    }

    margin-bottom: 14px;
    grid-area: footer;

    @media (max-width: $grid-md) {
      margin-bottom: 24px;
    }
  }

  &__available-links {
    display: flex;
    gap: 20px;
    grid-area: available-links;

    @media (max-width: $grid-sm) {
      gap: 0;
      justify-content: space-between;
    }
  }
}

.share-icons {
  display: inline-flex;
  vertical-align: middle;
  gap: 20px;

  &__item {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 28px;
    height: 28px;

    &--linkedin {
      background-image: url(../../images/icon-ginger.svg);
    }
    &--twitter {
      background-image: url(../../images/icon-1-ginger.svg);
    }
    &--facebook {
      background-image: url(../../images/icon-2-ginger.svg);
    }
    &--instagram {
      background-image: url(../../images/icon-3-ginger.svg);
    }
  }
}