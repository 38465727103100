@import '../../styles/vars';

.section-intro {
  position: relative;

  @media (max-width: $grid-lg) {
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px;
  }

  &::before {
    background: $color-ginger-light;
    border-radius: 20px;
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    width: calc(100% - 40px);
    height: 70%;
    z-index: -1;

    @media (max-width: $grid-lg) {
      border-radius: 0;
      left: 0;
      top: -96px;
      height: calc(70%);
      width: 100%;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 720px;
    padding: 80px 0 0;

    @media (max-width: $grid-lg) {
      padding-bottom: 0;
      padding-top: 60px;
    }
  }

  &__subtitle {
    color: #333;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 42px;

    @media (max-width: $grid-lg) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: $grid-sm) {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 0;
    }
  }

  &__book-wrapper {
    position: relative;
    order: 1;
    width: 720px;
    margin-top: 42px;
    max-width: 100%;

    @media (max-width: $grid-lg) {
      margin-bottom: 30px;
      order: 0;
    }
  }

  &__book-shape {

    @media (max-width: $grid-sm) {
      svg {
        max-width: 100%;
        max-height: 400px;
      }
    }
  }

  &__book {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 720px;

    @media (max-width: $grid-sm) {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 400px;
    }
  }

  &__ratings {
    display: flex;
    gap: 40px;
    flex-direction: row-reverse;
    margin-top: 60px;
    position: relative;

    @media (max-width: $grid-md) {
      flex-direction: column;
      gap: 0;
    }

    .ratings__rating {
      display: flex;
      flex-direction: column;
      gap: 0;
      justify-content: center;
      align-items: flex-start;

      @media (max-width: $grid-md) {
        flex-direction: row;
        justify-content: flex-start;
      }

      .ratings__reviews {
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        position: relative;
        top: -3px;

        @media (max-width: $grid-md) {
          display: none;
        }
      }

      .stars-rating__reviews-amount {
        @media (min-width: $grid-md) {
          display: none;
        }
      }
    }

    .stars-rating__logo {
      width: 100%;
    }

    .ratings__rating:nth-child(2) {
      .stars-rating__logo {
        top: 0;
      }
    }
  }

  .ribbon:not(.is-horizontal) {
    transform: translateY(-34px);
    right: 0;
  }
}