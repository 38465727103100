@import '../../styles/vars';

.section-read-first {
  background-image: url(../../images/bg@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding-bottom: 33%;
  position: relative;

  @media (max-width: $grid-md) {
    background: none;
    padding: 0;
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $grid-md) {
      position: static;
      transform: none;
    }
  }

  &__title {
    color: #fff;
    margin-bottom: 48px;

    @media (max-width: $grid-md) {
      color: #000;
      margin-bottom: 24px;
    }
  }
}