@import '../../styles/vars';

.stars-rating {
  $this: &;

  &_new {
    cursor: pointer;

    span {
      z-index: 0;
    }
  }

  &__star {

    + #{$this}__star { margin-left: 4px; }
  }

  &__value {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding-left: 8px;
    padding-top: 2px;

    @media (max-width: $grid-md) {
      font-size: 16px;
      line-height: 20px;
      justify-content: flex-start;
    }
  }

  &__reviews-amount {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 14px;
    padding-top: 2px;
  }

  &__source {
    margin-left: 16px;
  }

  &:first-child {
    .stars-rating__logo {
      position: relative;
      top: 3px;
    }
  }

  & span {
    cursor: pointer !important;
  }

  @media (max-width: $grid-sm) {
    margin-top: 16px;
  }
}

.stars-rating-source__container {
  display: flex;
  flex-direction: row-reverse;

  justify-content: center;
  align-items: center;
}