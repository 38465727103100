.nav {
  $this: &;

  &__link {
    color: inherit;
    font-size: 20px;
    line-height: 26px;
    padding: 40px 24px;
    text-decoration: none;
  }

  &--footer {

    #{$this}__link {
      padding: 10px 24px;
    }
  }
}