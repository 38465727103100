@import '../../styles/vars';

.text-with-image-link {
    background-color: #FFF4EF;
    background-image: url('../../images/background-shapes.svg');
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 50px 50px;
    position: relative;

    
    @media (max-width: $grid-md) {
        padding: 80px 32px 40px;
    }

    &__image {
        position: absolute;
        top: 0;
        transform: translateY(-50%);

        @media (max-width: $grid-md) {
            width: 50%;
        }
    }

    &__text {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
        text-align: center;

        @media (max-width: $grid-md) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }
}