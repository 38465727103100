.slick-dots {
  display: flex !important;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -56px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: $grid-md) {
    bottom: -30px;
  }

  li {
    background-color: #fff;
    border: 1px solid $color-blue;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    list-style: none;
    margin: 0 9px;

    button {
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    &.slick-active {
      background-color: $color-blue;

      button {
        background-color: $color-blue;
      }
    }
  }
}