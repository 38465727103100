@import '../../styles/vars';

.footer {
  background-color: #000;
  color: #fff;
  padding: 80px 0;

  @media (max-width: $grid-md) {
    padding: 40px 0;
  }

  &__content {
    padding: 0 20px;

    @media (max-width: $grid-xxl) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__nav {
    margin-bottom: 20px;
  }
}

.social {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  margin: 0 10px;
  width: 28px;
  height: 28px;

  &--lin { background-image: url(../../images/icon.svg); }
  &--gh { background-image: url(../../images/icon-1.svg); }
  &--fb { background-image: url(../../images/icon-2.svg); }
  &--ins { background-image: url(../../images/icon-3.svg); }
}

.copy {
  font-size: 14px;
  margin-top: 70px;
  text-align: center;
  opacity: .5;
}