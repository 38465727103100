@import '../styles/vars';

.section {
  margin-bottom: 85px;

  @media (max-width: $grid-md) {
    margin-bottom: 80px;
  }

  @media (max-width: $grid-xxl) {
    margin-left: 20px;
    margin-right: 20px;
  }

  &--intro {
    // margin-bottom: 385px;

    @media (max-width: $grid-md) {
      margin-bottom: 80px;
    }
  }

  // &--about-book {}

  // &--for-whom {}

  &--read-first {
    margin-bottom: 135px;

    @media (max-width: $grid-md) {
      margin-bottom: 80px;
    }
  }

  &--about-author {
    margin-bottom: 140px;

    @media (max-width: $grid-md) {
      margin-bottom: 80px;
    }
  }

  &--reviews {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 170px;

    @media (max-width: $grid-md) {
      margin-bottom: 100px;
    }
  }

  // &--faq {}

  &--table-of-contents {
    margin-bottom: 60px;
  }

  &--book-highlights,
  &--what-the-internet-says {
    margin-bottom: 160px;

    @media (max-width: $grid-xxl) {
      margin-bottom: 150px;
    }

    @media (max-width: $grid-md) {
      margin-bottom: 80px;
    }
  }

  &--links {
    display: flex;
    gap: 20px;
    margin-bottom: 120px;

    @media (max-width: $grid-md) {
      gap: 85px;
      flex-direction: column;
      margin-bottom: 80px;
    }
  }

  &--subscribe-form {
    margin-bottom: 165px;

    @media (max-width: $grid-md) {
      margin-bottom: 140px;
    }
  }
}