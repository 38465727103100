@import '../../styles/vars';

.header {
  padding: 40px 20px;

  @media (max-width: $grid-md) {
    padding: 26px 20px;
  }

  &__inner {
    padding: 0 20px;
  }

  &__nav {
    margin: -40px 0;

    @media (max-width: $grid-md) {
      margin: -26px 0;
    }
  }

  &__back-link {
    color: $color-ginger;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }

  &--only-back {
    background-color: #fff;
    box-shadow: 0 36px 47px -18px rgba(194, 201, 210, 0.25);
    margin-bottom: 65px;
    padding: 24px 20px;
    position: sticky;
    top: 0;
    z-index: 1;

    @media (max-width: $grid-md) {
      margin-bottom: 32px;
    }
  }
}